import React from "react";
import { Interweave } from "interweave";
import { serialize } from "../utils/SlateUtilityFunctions";

const SerializedOutput = ({ content }) => {
  // Add parent children node to editor content (this turns it into the "children" branch of editor object)
  const editorContent = { children: content };
  // console.log(editorContent);
  // console.log(serialize(editorContent));
  return (
    <div>
      <div className="flex font-sans prose prose-p:my-1 prose-h1:my-1 prose-h2:my-1 mx-4 mb-8 my-2">
        <Interweave content={serialize(editorContent)} />
      </div>
    </div>
  );
};

export default SerializedOutput;
