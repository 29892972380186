import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useNavigate, useLocation } from "react-router-dom";
import CastCard from "./CastCard";
import Rating from "./Rating";
import SerializedOutput from "../plugins/slate/components/SerializedOutput";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
// import useAuth from "../hooks/useAuth";

const MovieDetails = ({ movie }) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [movieSelect, setMovieSelect] = useState({ loading: false });
  const { loading, error, selectedMovie } = movieSelect;

  const [movieCastSelect, setMovieCastSelect] = useState({
    loading: false,
    selectedMovieCast: [],
  });
  const {
    loading: loadingCastSelect,
    error: errorCastSelect,
    selectedMovieCast,
  } = movieCastSelect;

  if (selectedMovieCast) {
    selectedMovieCast.sort((a, b) => a.castOrder - b.castOrder);
  }

  const filteredMovieCast = selectedMovieCast.filter(
    (cast) => cast.castOrder < 5
  );

  const selectMovie = async (movieId) => {
    try {
      setMovieSelect({ loading: true });
      const { data } = await axiosPrivate.get(`/api/movies/${movieId}`);
      setMovieSelect({ loading: false, selectedMovie: data });
    } catch (error) {
      if (error.response?.status === 403) {
        navigate("/login", { state: { from: location }, replace: true });
      }
      setMovieSelect({
        loading: false,
        selectedMovie: {},
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  const selectMovieCast = async (movieId) => {
    try {
      setMovieCastSelect({ loading: true, selectedMovieCast: [] });
      const { data } = await axiosPrivate.get(`/api/movies/${movieId}/cast`);
      setMovieCastSelect({ loading: false, selectedMovieCast: data });
    } catch (error) {
      if (error.response?.status === 403) {
        navigate("/login", { state: { from: location }, replace: true });
      }
      setMovieCastSelect({
        loading: false,
        selectedMovieCast: {},
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  // useEffect(() => {
  //   if (!auth?.userId) {
  //     navigate("/login");
  //   } else {
  //     selectMovie(movie.movieId);
  //     selectMovieCast(movie.movieId);
  //   }
  // }, [navigate, movie, auth]);

  useEffect(() => {
    selectMovie(movie.movieId);
    selectMovieCast(movie.movieId);
  }, [movie]);

  const movieDbBaseUrl = "https://image.tmdb.org/t/p/";

  return (
    <div className="bg-purple-50 overflow-x-hidden">
      {loading || loadingCastSelect ? (
        <Loader />
      ) : error || errorCastSelect ? (
        <Message variant="danger" text={error ? error : errorCastSelect} />
      ) : selectedMovie ? (
        <div className="ml-5 px-4 bg-gray-100 border-l border-r">
          {/* Heading */}
          <div className="flex justify-between items-center py-2 border-b border-gray-500">
            <div className="flex">
              <div className="text-lg font-semibold">{selectedMovie.name}</div>
              <div className="ml-4 text-lg">
                {selectedMovie.releaseDate.substring(0, 4)}
              </div>
            </div>
            <div className=" text-gray-700 px-3 text-sm md:block hidden">
              Last Updated: {new Date(movie.modifyDate).toDateString()}
            </div>
          </div>
          {/* Movie Details */}
          <div className="mt-3 bg-gray-100">
            {/* Grouping Section 1-3 */}
            <div className="flex lg:flex-row flex-col mb-6">
              {/* Section 1 */}
              <div className="flex-none ml-3">
                <img
                  className="rounded-lg shadow-md w-48 sm:w-60 lg:w-72 mr-5"
                  src={`${movieDbBaseUrl}/w342${selectedMovie.posterPath}`}
                  alt="test"
                />
              </div>
              {/* Grouping Section 2-3 */}
              <div className="flex flex-col">
                {/* Section 2 */}
                <div className="flex overflow-y-auto px-3 py-3">
                  {filteredMovieCast.map((cast) => (
                    <CastCard key={cast.movieCastId} castMember={cast} />
                  ))}
                </div>
                {/* Section 3 */}
                <div className="text-gray-700 py-4 px-4">
                  {selectedMovie.overview}
                </div>
              </div>
            </div>
            {/* Section 4 */}
            <div className="bg-gray-100">
              <div className=" flex flex-col mt-3 bg-white rounded-lg border shadow-md">
                <div className="flex md:flex-row flex-col my-3 items-start md:items-center md:justify-between">
                  <div className="flex px-4">
                    <div className="pr-2 text-gray-600 font-semibold">
                      Rating:
                    </div>
                    <Rating
                      rating={parseFloat(movie.rating)}
                      color={"text-red-400"}
                    />
                  </div>
                  <div className="flex px-4 mt-6 mb-3 md:my-0">
                    <div className="pr-2 text-gray-600 font-semibold">
                      Last Watched:
                    </div>
                    <div className="">{movie.watchedDate.substring(0, 10)}</div>
                  </div>
                </div>
                <p className="text-md text-gray-600 font-semibold px-4">
                  Notes:
                </p>
                <SerializedOutput content={JSON.parse(movie.notes)} />
                <div className="flex flex-row justify-end m-6">
                  <Link to={`/movies/${movie.trackedMovieId}`}>
                    <button className="px-9 py-3 bg-purple-500 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out">
                      Edit
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MovieDetails;
