import React from "react";
import Rating from "./Rating";

const MovieCard = ({ movie, handleCurrentMovie }) => {
  return (
    <>
      <button
        onClick={() => handleCurrentMovie(movie)}
        className="w-full px-4 py-3 border-b-2 border-x-2 shadow-lg hover:bg-gray-200 focus:bg-gray-300 focus:outline-none"
      >
        <div className="flex flex-col items-start sm:justify-between sm:flex-row">
          <div className="text-sm text-gray-900 font-semibold">
            {movie.movie.name}
          </div>
          <div className="text-gray-600 text-sm">
            {movie.movie.releaseDate.substring(0, 4)}
          </div>
        </div>
        <div className="hidden md:flex mt-2 text-sm text-gray-600">
          <div>Last Watched</div>
          <div className="ml-2">{movie.watchedDate.substring(0, 10)}</div>
        </div>
        <div className="flex text-gray-600 my-2">
          <Rating rating={parseFloat(movie.rating)} color={"text-red-400"} />
        </div>
      </button>
      <div className="bg-purple-50 py-2 shadow-sm"></div>
    </>
  );
};

export default MovieCard;
