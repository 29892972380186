import React, { useState, useEffect } from "react";
import Message from "../components/Message";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import TextEditor from "../plugins/slate/components/Editor";
import useAuth from "../hooks/useAuth";

const TrackMovieNewScreen = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [watchedDate, setWatchedDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [rating, setRating] = useState("3");
  const [editorText, setEditorText] = useState("");

  const [createMovie, setCreateMovie] = useState({});

  const location = useLocation();
  // TODO: check how this movie const is created
  const movie = location.state;

  const navigate = useNavigate();

  const {
    success: successCreate,
    error: errorCreate,
    createdMovie,
  } = createMovie;

  useEffect(() => {
    if (!auth?.userId) {
      navigate("/login");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (successCreate) {
      navigate("/", { state: { currentMovie: createdMovie } });
    }
  }, [navigate, successCreate, createdMovie, watchedDate]);

  const handleWatchedDateChange = (event) => {
    setWatchedDate(event.target.value);
  };

  const handleRatingChange = (event) => {
    setRating(event.target.value);
  };

  const handleCreate = (e) => {
    e.preventDefault();
    createTrackedMovie({
      movieId: movie.movieId,
      userId: auth.userId,
      watchedDate,
      rating,
      notes: JSON.stringify(editorText),
    });
  };

  const createTrackedMovie = async (newMovie) => {
    try {
      setCreateMovie({ loading: true, success: false });
      const { data } = await axiosPrivate.post("/api/trackedmovies", newMovie);
      setCreateMovie({ loading: false, success: true, createdMovie: data });
    } catch (error) {
      // Refresh token expired
      if (error.response?.status === 403) {
        // TODO: This doesn't work as returning to this screen after re-login will cause error
        // Can we pass the movie data back to login, then bring it in after re-login. Added movie to navigate below, but would need more in Login
        // Maybe we should set up a data object in state (vs state: {movie})
        navigate("/login", {
          state: { from: location, movie: movie },
          replace: true,
        });
      }
      setCreateMovie({
        loading: false,
        success: false,
        createdMovie: [],
        error: error.response?.data?.message
          ? error.response.data.message
          : error.message,
      });
    }
  };

  const handleCancel = () => {
    navigate("/");
  };

  return (
    <section className="h-screen bg-purple-50">
      <div className="px-6 mt-16 text-gray-800">
        <div className="">
          <div className="w-full flex justify-center py-8">
            <h1 className="text-2xl font-semibold">Track New Movie</h1>
          </div>
          {errorCreate ? (
            <Message variant="danger" text={errorCreate} />
          ) : movie ? (
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full sm:w-1/2 px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="watchedDate"
                >
                  Title
                </label>
                <div className="block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight">
                  {movie.name}
                </div>
              </div>
              <div className="w-full sm:w-1/2 px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="watchedDate"
                >
                  Release Date
                </label>
                <div className="block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight">
                  {movie.releaseDate.substring(0, 10)}
                </div>
              </div>
              <div className="w-full sm:w-1/2 px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="watchedDate"
                >
                  Watched date
                </label>
                <input
                  type="date"
                  id="watchedDate"
                  name="watchedDate"
                  value={watchedDate}
                  onChange={handleWatchedDateChange}
                  min="2018-01-01"
                  max="2030-12-31"
                  className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                />
              </div>
              <div className="w-full sm:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="rating"
                >
                  Rating
                </label>
                <select
                  value={rating}
                  onChange={handleRatingChange}
                  className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="rating"
                  placeholder="Rating"
                >
                  <option value="1">1</option>
                  <option value="1.5">1.5</option>
                  <option value="2">2</option>
                  <option value="2.5">2.5</option>
                  <option value="3">3</option>
                  <option value="3.5">3.5</option>
                  <option value="4">4</option>
                  <option value="4.5">4.5</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="Notes"
                >
                  Notes
                </label>
                <TextEditor setEditorText={setEditorText} />
              </div>
              <div className="text-center px-3 mt-6">
                <button
                  onClick={handleCreate}
                  className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  {" "}
                  Create
                </button>
              </div>
              <div className="text-center px-3 mt-6">
                <button
                  onClick={handleCancel}
                  className="inline-block px-7 py-3 bg-red-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  {" "}
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </section>
  );
};

export default TrackMovieNewScreen;
