import React, { useState, useEffect, useRef, useContext } from "react";
import AuthContext from "../contexts/AuthProvider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../api/axios";
// import axios from "axios";
import Message from "../components/Message.js";

const LoginScreen = () => {
  const { setAuth, persist, setPersist } = useContext(AuthContext);
  const userRef = useRef();
  const effectRan = useRef(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  // This redirect is example of how to bring user back to page after registering.
  // Only useful if pages can be visited without login so not needed here. Leave for now for reference
  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    userRef.current.focus();
    // This runs only once (with strict mode the component renders twice in development)
    if (effectRan.current === true || process.env.NODE_ENV !== "development") {
      // Checking for message from registation component
      if (location.state?.message) {
        setMessage({
          variant: location.state.message.variant,
          text: location.state.message.text,
        });
        location.state.message = null;
      }
    }
    return () => {
      effectRan.current = true;
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("media-persist", persist);
  }, [persist]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setMessage({ variant: "", text: "" });
    try {
      const { data } = await axios.post(
        "api/auth",
        JSON.stringify({ email, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      // Success
      const { userId, accessToken, name, isAdmin } = data;
      // TODO: remove password from auth
      setAuth({ userId, email, name, isAdmin, accessToken });
      setEmail("");
      setPassword("");
      navigate(from, { replace: true });
      // navigate(from, { replace: true, state: { movie: location.state.movie } });
    } catch (err) {
      if (!err?.response) {
        setMessage({ variant: "danger", text: "No Server Response" });
      } else if (err.response?.status === 400) {
        setMessage({ variant: "danger", text: "Missing Username or Password" });
      } else if (err.response?.status === 401) {
        setMessage({ variant: "danger", text: "Unauthorized" });
      } else {
        setMessage({ variant: "danger", text: "Login Failed" });
      }
    }
  };

  return (
    <section className="h-screen">
      <div className="mt-16 px-6 text-gray-800">
        <div className="flex justify-center items-center flex-wrap g-6">
          <div className="max-w-xs">
            <div className="flex justify-center py-8">
              <h1 className="text-2xl font-semibold">Sign In</h1>
            </div>
            {message?.text && (
              <Message variant={message.variant} text={message.text} />
            )}
            <form onSubmit={submitHandler}>
              <div className="w-full mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  value={email}
                  onChange={handleEmailChange}
                  type="text"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="email"
                  ref={userRef}
                  placeholder="Email address"
                  autoComplete="username"
                  required
                />
              </div>
              <div className="w-full mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  value={password}
                  onChange={handlePasswordChange}
                  type="password"
                  autoComplete="current-password"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="password"
                  placeholder="Password"
                />
              </div>
              <div className="flex justify-between items-center mb-6">
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    id="trust-check"
                    onChange={togglePersist}
                    checked={persist}
                  />
                  <label
                    htmlFor="trust-check"
                    className="form-check-label inline-block text-gray-800"
                  >
                    Trust this device
                  </label>
                </div>
                {/* <a href="#!" className="text-gray-800">
                  Forgot password?
                </a> */}
              </div>
              <div className="text-center lg:text-left">
                <button
                  type="submit"
                  className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  Login
                </button>
                <p className="text-sm mt-2 pt-1 mb-0">
                  Don&apos;t have an account?
                </p>
                <Link
                  className=" font-bold text-red-500 hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out"
                  to={redirect ? `/register?redirect=${redirect}` : "/register"}
                >
                  Sign Up
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginScreen;
