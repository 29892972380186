const hotkeys = [
  {
    keyPress: "mod+b",
    format: "bold",
    type: "mark",
  },
  {
    keyPress: "mod+i",
    format: "italic",
    type: "mark",
  },
  {
    keyPress: "mod+u",
    format: "underline",
    type: "mark",
  },
  {
    keyPress: "mod+5",
    format: "strikethrough",
    type: "mark",
  },
  {
    keyPress: "mod+alt+1",
    format: "headingOne",
    type: "block",
  },
  {
    keyPress: "mod+alt+2",
    format: "headingTwo",
    type: "block",
  },
  {
    keyPress: "mod+alt+3",
    format: "headingThree",
    type: "block",
  },
  {
    keyPress: "mod+q",
    format: "blockquote",
    type: "block",
  },
  {
    keyPress: "mod+alt+4",
    format: "orderedList",
    type: "block",
  },
  {
    keyPress: "mod+alt+5",
    format: "unorderedList",
    type: "block",
  },
  {
    keyPress: "mod+L",
    format: "alignLeft",
    type: "block",
  },
  {
    keyPress: "mod+J",
    format: "alignCenter",
    type: "block",
  },
  {
    keyPress: "mod+R",
    format: "alignRight",
    type: "block",
  },
  {
    keyPress: "Tab",
    format: "tab",
    type: "block",
  },
];

export default hotkeys;
