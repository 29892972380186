import React, { useState, useEffect } from "react";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";

const AllMoviesScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { keyword } = useParams();
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [search, setSearch] = useState("");
  const [movieFetch, setMovieFetch] = useState({ loading: false, movies: [] });

  const { loading, error, movies } = movieFetch;

  useEffect(() => {
    if (!auth?.userId) {
      navigate("/login");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (keyword) {
      getMovies(keyword);
    }
  }, [keyword]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleMovieSearch = () => {
    if (search.trim()) {
      navigate(`/allmovies/search/${search}`);
    } else {
      navigate("/allmovies");
    }
  };

  const getMovies = async (keyword = "") => {
    try {
      setMovieFetch({ loading: true, movies: [] });
      const { data } = await axiosPrivate.get(`/api/movies?keyword=${keyword}`);
      setMovieFetch({ loading: false, movies: data });
    } catch (error) {
      // Refresh token expired
      if (error.response?.status === 403) {
        navigate("/login", { state: { from: location }, replace: true });
      }
      setMovieFetch({
        loading: false,
        movies: [],
        error: error.response?.data?.message
          ? error.response.data.message
          : error.message,
      });
    }
  };

  const movieDbBaseUrl = "https://image.tmdb.org/t/p/";

  return (
    <section className="h-screen bg-purple-50">
      <div className="px-6 mt-16 text-gray-800">
        <div className="">
          <div className="w-full flex justify-center py-8">
            <h1 className="text-2xl font-semibold">Search Available Movies</h1>
          </div>
          {error ? (
            <Message variant="danger" text={error} />
          ) : (
            <div className="flex justify-center items-center flex-wrap g-6">
              <div className="mb-4 flex flex-row">
                <input
                  value={search}
                  onChange={handleSearchChange}
                  type="text"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-3 mr-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="filter"
                  placeholder="Search Available Movies"
                />

                <button
                  onClick={handleMovieSearch}
                  className="inline-block px-6 bg-blue-500 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  Search
                </button>
              </div>
              <div className="min-w-full">
                {movies ? (
                  loading ? (
                    <Loader />
                  ) : (
                    <table className="min-w-full shadow-md rounded bg-gray-100">
                      <thead className="bg-gray-50">
                        <tr>
                          <th></th>
                          <th className="p-2 text-left font-bold">Title</th>
                          <th className="p-2 text-left font-bold">
                            Release Date
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-300">
                        {movies.map((movie) => (
                          <tr key={movie.movieId}>
                            <td>
                              <div className="w-12 h-16 hover:w-36 hover:h-48">
                                <img
                                  className="h-full w-full object-contain shadow-md"
                                  src={`${movieDbBaseUrl}/w342${movie.posterPath}`}
                                  alt="test"
                                />
                              </div>
                            </td>
                            <td className="p-3">{movie.name}</td>
                            <td className="p-3">
                              {movie.releaseDate.substring(0, 10)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AllMoviesScreen;
