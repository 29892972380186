import React, { useCallback, useMemo } from "react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import { Slate, Editable, withReact } from "slate-react";
import Toolbar from "./Toolbar";
import {
  getMarked,
  getBlock,
  toggleMark,
  toggleBlock,
} from "../utils/SlateUtilityFunctions.js";
import isHotkey from "is-hotkey";
import hotkeys from "../data/hotkeys";
import { useEffect } from "react";

const Element = (props) => {
  return getBlock(props);
};

const Leaf = ({ attributes, children, leaf }) => {
  children = getMarked(leaf, children);
  return <span {...attributes}>{children}</span>;
};

const TextEditor = ({ initialText, setEditorText }) => {
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  initialText = initialText
    ? initialText
    : [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ];

  const handleEditorChange = (newValue) => {
    setEditorText(newValue);
  };

  const renderElement = useCallback((props) => <Element {...props} />, []);

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  // Only to be run on initial mount of component. We beed to set the EditorText incase the user clicks Update without making any changes
  useEffect(() => {
    setEditorText(initialText);
  }, []);

  return (
    <div>
      <div className="max-w-3xl my-2 rounded border border-slate-700 bg-gray-50">
        <Slate
          editor={editor}
          value={initialText}
          onChange={handleEditorChange}
        >
          <div className="h-11 flex flex-wrap py-2 gap-y-1 px-2.5 border-b border-slate-700 bg-white">
            <Toolbar />
          </div>
          <div className="font-sans px-3 min-h-60 editor-wrapper prose">
            <Editable
              placeholder="Write something"
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              // This section handles hotkeys
              onKeyDown={(event) => {
                // console.log(event);
                hotkeys.forEach((hotkey) => {
                  if (isHotkey(hotkey.keyPress, event)) {
                    event.preventDefault();
                    if (hotkey.type === "block") {
                      toggleBlock(editor, hotkey.format);
                    }
                    if (hotkey.type === "mark") {
                      toggleMark(editor, hotkey.format);
                    }
                  }
                });
              }}
            />
          </div>
        </Slate>
      </div>
    </div>
  );
};

export default TextEditor;
