import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../contexts/AuthProvider";
import Message from "../components/Message.js";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const ProfileScreen = () => {
  const { auth } = useAuth();
  const { setAuth } = useContext(AuthContext);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [updatedProfile, setUpdatedProfile] = useState({
    success: false,
  });

  const { error, success } = updatedProfile;

  useEffect(() => {
    setName(auth.name);
    setEmail(auth.email);
    setPassword("");
    setConfirmPassword("");
  }, []);

  useEffect(() => {
    if (!auth?.userId) {
      navigate("/login");
    }
    if (success) {
      setMessage({ variant: "success", text: "Profile Updated" });
      setAuth((prev) => {
        // console.log(prev);
        return { ...prev, name: name, email: email };
      });
      setUpdatedProfile({ success: false });
      setName(name);
      setEmail(email);
    }
  }, [success, auth, navigate]);

  const updateProfile = async (updatedUser) => {
    try {
      setUpdatedProfile({ loading: true, user: [] });
      const { data } = await axiosPrivate.put(`/api/users`, updatedUser);
      setUpdatedProfile({
        success: true,
      });
      setMessage(data);
    } catch (error) {
      // Refresh token expired
      if (error.response?.status === 403) {
        navigate("/login", { state: { from: location }, replace: true });
      }
      setUpdatedProfile({
        success: false,
        error: error.response?.data?.message
          ? error.response.data.message
          : error.message,
      });
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage({ variant: "danger", text: "Passwords do not match" });
    } else {
      // dispatch(updateUserProfile({ id: user.user_id, name, email, password }));
      updateProfile({ userId: auth.userId, name, email, password });
    }
  };

  return (
    <section className="h-screen">
      <div className="px-6 mt-16 text-gray-800">
        <div className="flex justify-center items-center flex-wrap g-6">
          <div className="max-w-xs">
            <div className="flex justify-center py-8">
              <h1 className="text-2xl font-semibold">Profile</h1>
            </div>
            {message && (
              <Message variant={message.variant} text={message.text} />
            )}
            {error && <Message variant="danger" text={error} />}
            <form onSubmit={submitHandler}>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    value={name}
                    onChange={handleNameChange}
                    type="text"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="name"
                    placeholder="Name"
                  />
                </div>
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    value={email}
                    onChange={handleEmailChange}
                    type="text"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="email"
                    placeholder="Email address"
                  />
                </div>
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                    value={password}
                    onChange={handlePasswordChange}
                    type="password"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="password"
                    placeholder="Password"
                  />
                </div>
                <div className="w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="confirmPassword"
                  >
                    Confirm Password
                  </label>
                  <input
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    type="password"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                  />
                </div>
                <div className="text-center px-3 mt-6">
                  <button
                    type="submit"
                    className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    {" "}
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfileScreen;
