import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Message from "../components/Message";
import Loader from "../components/Loader";
import TextEditor from "../plugins/slate/components/Editor";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";

const TrackedMovieEditScreen = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [watchedDate, setWatchedDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [rating, setRating] = useState("");
  const [editorText, setEditorText] = useState("");
  const [selectedMovie, setSelectedMovie] = useState({
    loading: false,
    success: false,
    movie: null,
  });
  const [updatedMovie, setUpdatedMovie] = useState({
    loading: false,
    success: false,
    cancel: false,
  });

  const { loading, error, movie: trackedMovie } = selectedMovie;

  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
    cancel: cancelUpdate,
  } = updatedMovie;

  useEffect(() => {
    if (!auth?.userId) {
      navigate("/login");
    }
    if (!trackedMovie) {
      selectTrackedMovie(id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (successUpdate) {
      navigate("/", { state: { currentMovie: updatedMovie.movie } });
    }
    if (cancelUpdate) {
      navigate("/", { state: { currentMovie: selectedMovie.movie } });
    }
    if (trackedMovie) {
      setWatchedDate(trackedMovie.watchedDate.substring(0, 10));
      setRating(trackedMovie.rating);
    }
  }, [
    navigate,
    trackedMovie,
    selectedMovie,
    updatedMovie,
    successUpdate,
    cancelUpdate,
  ]);

  const handleWatchedDateChange = (event) => {
    setWatchedDate(event.target.value);
  };

  const handleRatingChange = (event) => {
    setRating(event.target.value);
  };

  const submitHandler = () => {
    // e.preventDefault();
    updateTrackedMovie({
      trackedMovieId: id,
      watchedDate,
      rating,
      notes: JSON.stringify(editorText),
    });
  };

  const handleCancel = () => {
    setUpdatedMovie({ cancel: true });
  };

  const selectTrackedMovie = async (id) => {
    try {
      setSelectedMovie({ loading: true });
      const { data } = await axiosPrivate.get("/api/trackedmovies/" + id);
      setSelectedMovie({ loading: false, success: true, movie: data });
    } catch (error) {
      // Refresh token expired
      if (error.response?.status === 403) {
        navigate("/login", { state: { from: location }, replace: true });
      }
      setSelectedMovie({
        loading: false,
        success: false,
        error: error.response?.data?.message
          ? error.response.data.message
          : error.message,
      });
    }
  };

  const updateTrackedMovie = async (trackedMovie) => {
    try {
      setUpdatedMovie({ loading: true, movie: [] });
      const { data } = await axiosPrivate.put(
        `/api/trackedmovies/${trackedMovie.trackedMovieId}`,
        trackedMovie
      );
      setUpdatedMovie({ loading: false, success: true, movie: data });
    } catch (error) {
      // Refresh token expired
      if (error.response?.status === 403) {
        navigate("/login", { state: { from: location }, replace: true });
      }
      setUpdatedMovie({
        loading: false,
        success: false,
        error: error.response?.data?.message
          ? error.response.data.message
          : error.message,
      });
    }
  };

  return (
    <section className="h-screen">
      <div className="px-6 mt-16 text-gray-800 bg-purple-50">
        <div className="flex justify-center items-center flex-wrap g-6">
          <div className="max-w-2xl">
            {loading || loadingUpdate ? (
              <Loader />
            ) : error ? (
              <Message variant="danger" text={error} />
            ) : errorUpdate ? (
              <Message variant="danger" text={errorUpdate} />
            ) : (
              <div>
                {trackedMovie && (
                  <div className="flex justify-center pt-8">
                    <div className="flex mb-6">
                      <span className="text-xl text-gray-900 font-semibold mr-4 ">
                        {trackedMovie.movie.name}
                      </span>
                      <span className="text-gray-700 font-semibold text-xl">
                        {trackedMovie.movie.releaseDate.substring(0, 4)}
                      </span>
                    </div>
                  </div>
                )}
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full sm:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="watchedDate"
                    >
                      Watched date
                    </label>
                    <input
                      type="date"
                      id="watchedDate"
                      name="watchedDate"
                      value={watchedDate}
                      onChange={handleWatchedDateChange}
                      min="2018-01-01"
                      max="2030-12-31"
                      className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                  </div>
                  <div className="w-full sm:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="rating"
                    >
                      Rating
                    </label>
                    <select
                      value={rating ? parseFloat(rating) : ""}
                      onChange={handleRatingChange}
                      className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="rating"
                      placeholder="Rating"
                    >
                      <option value="1">1</option>
                      <option value="1.5">1.5</option>
                      <option value="2">2</option>
                      <option value="2.5">2.5</option>
                      <option value="3">3</option>
                      <option value="3.5">3.5</option>
                      <option value="4">4</option>
                      <option value="4.5">4.5</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="Notes"
                    >
                      Notes
                    </label>
                    {trackedMovie ? (
                      <TextEditor
                        initialText={JSON.parse(trackedMovie.notes)}
                        setEditorText={setEditorText}
                      />
                    ) : null}
                  </div>
                  <div className="text-center px-3 mt-6">
                    <button
                      onClick={submitHandler}
                      className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      {" "}
                      Update
                    </button>
                  </div>
                  <div className="text-center px-3 mt-6">
                    <button
                      onClick={handleCancel}
                      className="inline-block px-7 py-3 bg-red-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      {" "}
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrackedMovieEditScreen;
