import React, { useState, useEffect } from "react";
import MovieCard from "../components/MovieCard";
import Loader from "../components/Loader";
import MovieDetails from "../components/MovieDetails";
import Message from "../components/Message";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";

const HomeScreen = () => {
  const location = useLocation();
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [currentMovie, setCurrentMovie] = useState(null);
  const [sort, setSort] = useState("sortByName");
  const [sortAscending, setSortAscending] = useState(true);
  const [filter, setFilter] = useState("");
  const [trackedMovieList, setTrackedMovieList] = useState({
    loading: false,
    trackedMovies: [],
  });

  const navigate = useNavigate();

  // console.log(auth);

  // Checking if returned from page with current movie previously set (stored in location.statee)
  if (location.state && !currentMovie) {
    setCurrentMovie(location.state.currentMovie);
  }

  const { loading, error, trackedMovies } = trackedMovieList;

  const compareMovieNames = (a, b) => {
    const nameA = a.movie.name.toUpperCase();
    const nameB = b.movie.name.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  const compareMovieRatings = (a, b) => {
    return parseFloat(a.rating) - parseFloat(b.rating);
  };

  const compareMovieDates = (a, b) => {
    return parseInt(a.movie.releaseDate) - parseInt(b.movie.releaseDate);
  };

  const filterMovieNames = (trackedMovie) => {
    return trackedMovie.movie.name
      .replace(/\s+/g, "")
      .toUpperCase()
      .includes(filter.replace(/\s+/g, "").toUpperCase());
  };

  if (trackedMovies.length > 0) {
    switch (sort) {
      case "sortByName":
        trackedMovies.sort(compareMovieNames);
        break;
      // trackedMovies.reverse(compareMovieNames)
      case "sortByDate":
        trackedMovies.sort(compareMovieDates);
        break;
      case "sortByRating":
        trackedMovies.sort(compareMovieRatings);
        break;
      default:
        trackedMovies.sort(compareMovieNames);
    }
    if (!sortAscending) trackedMovies.reverse();
  }

  let movies = trackedMovies;

  if (filter !== "" && trackedMovies.length !== 0) {
    movies = trackedMovies.filter(filterMovieNames);
    // Check if currently selected movie is filtered out of list
    if (
      currentMovie &&
      !movies.find((movie) => movie.movieid === currentMovie.movieid)
    ) {
      setCurrentMovie(null);
    }
  }

  const listTrackedMovies = async () => {
    try {
      if (!loading && trackedMovies.length === 0) {
        setTrackedMovieList((prevState) => ({ ...prevState, loading: true }));
        const { data } = await axiosPrivate.get(`/api/trackedmovies`);
        setTrackedMovieList({ loading: false, trackedMovies: data });
      }
    } catch (error) {
      // console.log(`Error Response: ${JSON.stringify(error.response)}`);
      // Refresh token expired
      if (error.response?.status === 403) {
        navigate("/login", { state: { from: location }, replace: true });
      }
      setTrackedMovieList({
        loading: false,
        trackedMovies: [],
        error: error.response?.data?.message
          ? error.response.data.message
          : error.message,
      });
    }
  };

  useEffect(() => {
    if (!auth?.userId) {
      navigate("/login");
    } else {
      listTrackedMovies();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCurrentMovie = (movie) => {
    setCurrentMovie(movie);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleSortToggle = () => {
    setSortAscending(!sortAscending);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <div className="h-screen flex flex-1">
      <div className="mt-16 flex flex-col min-w-40 sm:min-w-60 md:min-w-66  bg-gray-100 ">
        <div className="px-4 py-3 flex items-center justify-between border-b-2 border-x-2 shadow-lg">
          <div className="flex flex-col">
            <div className="flex flex-row justify-between mb-2">
              <label className="hidden sm:block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 py-2 pr-5">
                Sort:{" "}
              </label>
              <select
                value={sort}
                onChange={handleSortChange}
                className="font-normal text-gray-700 appearance-none w-full h-8 px-2 py-0 bg-gray-100 border"
                id="movieSort"
              >
                <option value="sortByDate">By Date</option>
                <option value="sortByName">By Name</option>
                <option value="sortByRating">By Rating</option>
              </select>
              <button className="pl-3 pb-1" onClick={handleSortToggle}>
                {sortAscending ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4"
                    />
                  </svg>
                )}
              </button>
            </div>
            <div className="flex flex-row">
              <label className="hidden sm:block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 py-3 pr-3">
                Filter:{" "}
              </label>
              <input
                value={filter}
                onChange={handleFilterChange}
                type="text"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-3 mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="filter"
                placeholder="Filter"
              />
            </div>
          </div>
        </div>
        <div className="bg-purple-50 py-2 shadow-sm"></div>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger" text={error} />
        ) : (
          <div className=" overflow-y-auto">
            {movies.map((movie) => (
              <MovieCard
                key={movie.movieId}
                movie={movie}
                handleCurrentMovie={handleCurrentMovie}
              />
            ))}
          </div>
        )}
      </div>
      <div className="flex mt-16 w-full overflow-y-auto bg-purple-50">
        {currentMovie ? <MovieDetails movie={currentMovie} /> : ""}
      </div>
    </div>
  );
};

export default HomeScreen;
