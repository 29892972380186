import React from "react";

const CastCard = ({ castMember }) => {
  const movieDbBaseUrl = "https://image.tmdb.org/t/p/";
  return (
    <div className="flex-none rounded-lg shadow-md mr-5 max-w-24">
      {castMember.profilePath !== null ? (
        <img
          className="object-cover rounded-t-lg w-24"
          src={`${movieDbBaseUrl}/w185${castMember.profilePath}`}
          alt="test"
        />
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="object-cover rounded-t-lg w-24"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
            clipRule="evenodd"
          />
        </svg>
      )}
      <div>
        {castMember.job === "Director" ? (
          <div>
            <p className="px-2 mb-1 text-sm font-medium tracking-tight text-gray-700">
              Directed By
            </p>
            <p className="px-2 mb-3 font-normal text-xs text-gray-700">
              {castMember.name}
            </p>
          </div>
        ) : (
          <div>
            <p className="px-2 my-1 font-medium text-sm text-gray-800">
              {castMember.name}
            </p>
            <p className="px-2 mb-3 font-normal text-xs text-gray-700">
              {castMember.character}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CastCard;
