import React from "react";

const Button = (props) => {
  const { children, format, active, ...rest } = props;
  return (
    <button
      className={
        (active ? "opacity-100" : "opacity-50") +
        " bg-white border-0 w-7 h-5 mx-1"
      }
      title={format}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
