import React from "react";
import { useSlate } from "slate-react";
import Button from "./Button";
import Icon from "./Icon";
import {
  toggleBlock,
  toggleMark,
  isMarkActive,
  isBlockActive,
} from "../utils/SlateUtilityFunctions.js";
import defaultToolbarGroups from "../data/toolbarGroups.js";

const Toolbar = () => {
  const editor = useSlate();
  const toolbarGroups = defaultToolbarGroups;

  const BlockButton = ({ format }) => {
    return (
      <Button
        active={isBlockActive(editor, format)}
        format={format}
        onMouseDown={(e) => {
          e.preventDefault();
          toggleBlock(editor, format);
        }}
      >
        <Icon icon={format} />
      </Button>
    );
  };

  const MarkButton = ({ format }) => {
    return (
      <Button
        active={isMarkActive(editor, format)}
        format={format}
        onMouseDown={(e) => {
          e.preventDefault();
          toggleMark(editor, format);
        }}
      >
        <Icon icon={format} />
      </Button>
    );
  };

  return (
    <div>
      <div className="toolbar">
        {toolbarGroups.map((group, index) => (
          <span
            key={index}
            className="md:mx-3 sm:mx-1 cursor-pointer toolbar-grp"
          >
            {group.map((element) => {
              switch (element.type) {
                case "block":
                  return <BlockButton key={element.id} {...element} />;
                case "mark":
                  return <MarkButton key={element.id} {...element} />;
                default:
                  return null;
              }
            })}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Toolbar;
