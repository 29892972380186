import { Editor, Text, Transforms, Element as SlateElement } from "slate";
import escapeHtml from "escape-html";

const alignment = ["alignLeft", "alignRight", "alignCenter"];
const list_types = ["orderedList", "unorderedList"];

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = list_types.includes(format);
  const isIndent = alignment.includes(format);
  const isAligned = alignment.some((alignmentType) =>
    isBlockActive(editor, alignmentType)
  );

  /*If the node is already aligned and change in indent is called we should unwrap it first and split the node to prevent
    messy, nested DOM structure and bugs due to that.*/
  if (isAligned && isIndent) {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        alignment.includes(
          !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
        ),
      split: true,
    });
  }

  /* Wraping the nodes for alignment, to allow it to co-exist with other block level operations*/
  if (isIndent) {
    Transforms.wrapNodes(editor, {
      type: format,
      children: [],
    });
    return;
  }
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      list_types.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
      ),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  });

  if (isList && !isActive) {
    Transforms.wrapNodes(editor, {
      type: format,
      children: [],
    });
  }
};

export const addMarkData = (editor, data) => {
  Editor.addMark(editor, data.format, data.value);
};

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);

  return marks ? marks[format] === true : false;
};

export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

export const activeMark = (editor, format) => {
  const defaultMarkData = {
    color: "black",
    bgColor: "black",
    fontSize: "normal",
    fontFamily: "sans",
  };
  const marks = Editor.marks(editor);
  const defaultValue = defaultMarkData[format];
  return marks?.[format] ?? defaultValue;
};

export const getMarked = (leaf, children) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  if (leaf.code) {
    children = <code>{children}</code>;
  }
  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.strikethrough) {
    children = (
      <span style={{ textDecoration: "line-through" }}>{children}</span>
    );
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  return children;
};

export const getBlock = (props) => {
  const { element, children } = props;
  const attributes = props.attributes ?? {};

  switch (element.type) {
    case "headingOne":
      return (
        <h1 {...attributes} {...element.attr}>
          {children}
        </h1>
      );
    case "headingTwo":
      return (
        <h2 {...attributes} {...element.attr}>
          {children}
        </h2>
      );
    case "headingThree":
      return (
        <h3 {...attributes} {...element.attr}>
          {children}
        </h3>
      );
    case "blockquote":
      return (
        <blockquote {...attributes} {...element.attr}>
          {children}
        </blockquote>
      );
    case "alignLeft":
      return (
        <div
          style={{ listStylePosition: "inside" }}
          {...attributes}
          {...element.attr}
        >
          {children}
        </div>
      );
    case "alignCenter":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            listStylePosition: "inside",
            flexDirection: "column",
          }}
          {...attributes}
          {...element.attr}
        >
          {children}
        </div>
      );
    case "alignRight":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            listStylePosition: "inside",
            flexDirection: "column",
          }}
          {...attributes}
          {...element.attr}
        >
          {children}
        </div>
      );
    case "list-item":
      return (
        <li {...attributes} {...element.attr}>
          {children}
        </li>
      );
    case "orderedList":
      return (
        <ol type="1" {...attributes}>
          {children}
        </ol>
      );
    case "unorderedList":
      return <ul {...attributes}>{children}</ul>;
    // This is the start of adding tabs to the editor, currently it just toggles between one tab on/off for whole block
    case "tab":
      // console.log(props);
      return (
        <div className="pl-1" {...attributes} {...element.attr}>
          {children}
        </div>
      );
    default:
      return (
        <div {...element.attr} {...attributes}>
          {children}
        </div>
      );
  }
};

export const serialize = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    // console.log(`string: ${string}`);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.code) {
      string = `<code>${string}</code>`;
    }

    if (node.italic) {
      string = `<em>${string}</em>`;
    }

    if (node.underline) {
      string = `<u>${string}</u>`;
    }

    if (node.strikethrough) {
      string = `<s>${string}</s>`;
    }

    if (string === "") {
      string = `<br>`; //`<div className="mt-8">${string}</div>`;
    }

    return string;
  }

  const children = node.children.map((n) => serialize(n)).join("");

  switch (node.type) {
    case "blockquote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "unorderedList":
      return `<ul>${children}</ul>`;
    case "headingOne":
      return `<h1>${children}</h1>`;
    case "headingTwo":
      return `<h2>${children}</h2>`;
    case "headingThree":
      return `<h3>${children}</h3>`;
    case "list-item":
      return `<li>${children}</li>`;
    case "orderedList":
      return `<ol>${children}</ol>`;
    case "paragraph":
      return `<p>${children}</p>`;
    default:
      return children;
  }
};
