import React, { useState, useEffect } from "react";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";

const AddMovieScreen = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [search, setSearch] = useState("");
  const [movieDbSearch, setMovieDbSearch] = useState({
    loading: false,
    movies: [],
  });
  const [movieCreate, setMovieCreate] = useState({});

  const { loading, error, movies } = movieDbSearch;
  const { error: errorCreate, success: successCreate } = movieCreate;

  useEffect(() => {
    if (!auth?.userId) {
      navigate("/login");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleMovieSearch = () => {
    searchMovieDb(search);
  };

  const handleAddMovie = (
    movieDbId,
    name,
    releaseDate,
    overview,
    posterPath
  ) => {
    createMovie(movieDbId, name, releaseDate, overview, posterPath);
  };

  const searchMovieDb = async (searchstring) => {
    try {
      setMovieDbSearch({ loading: true, movies: [] });
      const { data } = await axiosPrivate.get(
        `/api/themoviedb/${searchstring}`
      );
      setMovieDbSearch({ loading: false, movies: data });
    } catch (error) {
      // Refresh token expired
      if (error.response?.status === 403) {
        navigate("/login", { state: { from: location }, replace: true });
      }
      setMovieDbSearch({
        loading: false,
        selectedMovie: [],
        error: error.response?.data?.message
          ? error.response.data.message
          : error.message,
      });
    }
  };

  const createMovie = async (
    movieDbId,
    name,
    releaseDate,
    overview,
    posterPath
  ) => {
    try {
      const { data } = await axiosPrivate.post("api/movies", {
        movieDbId,
        name,
        releaseDate,
        overview,
        posterPath,
      });
      setMovieCreate({ success: true, movie: data });
    } catch (error) {
      setMovieCreate({
        success: false,
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  const movieDbBaseUrl = "https://image.tmdb.org/t/p/";

  return (
    <section className="h-screen bg-purple-50">
      <div className="px-6 mt-16 text-gray-800">
        <div className="flex justify-center items-center flex-wrap g-6">
          <div className="w-full flex justify-center py-8">
            <h1 className="text-2xl font-semibold">Add Movie</h1>
          </div>
          <div className="mb-4 flex flex-row">
            <input
              value={search}
              onChange={handleSearchChange}
              type="text"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-3 mr-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="filter"
              placeholder="Search Movies"
              data-testid="search-input"
            />

            <button
              onClick={handleMovieSearch}
              className="inline-block px-6 bg-blue-500 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              data-testid="search-button"
            >
              Search
            </button>
          </div>
          <div className="min-w-full">
            {errorCreate && <Message variant="danger" text={errorCreate} />}
            {successCreate && (
              <Message
                variant="success"
                text={`${movieCreate.movie.name} added to application`}
              />
            )}
            {movies ? (
              loading ? (
                <Loader />
              ) : error ? (
                <Message variant="danger" text={error} />
              ) : (
                <table className="min-w-full shadow-md rounded bg-gray-100">
                  <thead className="bg-gray-50">
                    <tr>
                      <th></th>
                      <th className="p-2 text-left font-bold">Title</th>
                      <th className="p-2 text-left font-bold">Release Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-300">
                    {movies.map((movie) => (
                      <tr key={movie.id}>
                        <td>
                          <div className="w-12 h-16 hover:w-36 hover:h-48">
                            <img
                              className="h-full w-full object-contain shadow-md"
                              src={`${movieDbBaseUrl}/w342${movie.poster_path}`}
                              alt="test"
                            />
                          </div>
                        </td>
                        <td className="p-3" data-testid="title">
                          {movie.title}
                        </td>
                        <td className="p-3">{movie.release_date}</td>
                        <td>
                          <button
                            className="inline-block px-4 py-2 bg-blue-500 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                            onClick={() =>
                              handleAddMovie(
                                movie.id,
                                movie.title,
                                movie.release_date,
                                movie.overview,
                                movie.poster_path
                              )
                            }
                          >
                            Add
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddMovieScreen;
