import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import TrackedMovieEditScreen from "./screens/TrackedMovieEditScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
import AddMovieScreen from "./screens/AddMovieScreen";
import TrackMovieNewScreen from "./screens/TrackMovieNewScreen";
import SearchAvailableMoviesScreen from "./screens/SearchAvailableMoviesScreen";
import AllMoviesScreen from "./screens/AllMoviesScreen";
import PersistLogin from "./components/PersistLogin";

const App = () => {
  return (
    <Router>
      <Header />
      <main className="max-w-screen-xl">
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/register" element={<RegisterScreen />} />

          <Route element={<PersistLogin />}>
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/movies/:id" element={<TrackedMovieEditScreen />} />
            <Route path="/addmovie" element={<AddMovieScreen />} />
            <Route path="/tracknewmovie" element={<TrackMovieNewScreen />} />
            <Route
              path="/searchmovie/search/:keyword"
              element={<SearchAvailableMoviesScreen />}
            />
            <Route
              path="/searchmovie"
              element={<SearchAvailableMoviesScreen />}
            />
            <Route
              path="/allmovies/search/:keyword"
              element={<AllMoviesScreen />}
            />
            <Route path="/allmovies" element={<AllMoviesScreen />} />
            <Route path="/" element={<HomeScreen />} exact />
          </Route>
        </Routes>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
