import axios from "../api/axios";
// import axios from "axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.get("api/auth/refresh", {
      withCredentials: true,
    });
    setAuth((prev) => {
      console.log(JSON.stringify(prev));
      console.log(`response: ${JSON.stringify(response.data)}`);
      //Overwrite existing access token with new access token
      return {
        ...prev,
        userId: response.data.userId,
        name: response.data.name,
        email: response.data.email,
        isAdmin: response.data.isAdmin,
        accessToken: response.data.accessToken,
      };
    });
    return response.data.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
