import { useContext } from "react";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import AuthContext from "../contexts/AuthProvider";
// import axios from "axios";
import axios from "../api/axios";

const Header = () => {
  const { auth } = useAuth();
  const { setAuth } = useContext(AuthContext);

  const logoutHandler = async () => {
    try {
      await axios.get("api/auth/logout", {
        withCredentials: true,
      });
      setAuth({});
    } catch (error) {
      setAuth({});
    }
  };

  const toggleUserMenu = () => {
    document.getElementById("userDropdown").classList.toggle("hidden");
  };

  window.onclick = (event) => {
    if (!event.target.matches(".userMenuButton")) {
      if (document.getElementById("userDropdown")) {
        if (
          !document.getElementById("userDropdown").classList.contains("hidden")
        ) {
          document.getElementById("userDropdown").classList.add("hidden");
        }
      }
    }
  };

  const toggleMobileMenu = () => {
    document.getElementById("mobileMenu").classList.toggle("hidden");
    // console.log(document.getElementById("userDropdown").classList)
  };

  return (
    <div className="min-h-full max-w-screen-xl">
      <nav className="bg-purple-600 fixed top-0 z-30 h-16 max-w-screen-xl w-full">
        <div className="mx-auto px-3">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              {auth?.userId ? (
                <div className="hidden md:block">
                  <div className="flex items-baseline space-x-4">
                    <Link to={"/"}>
                      <button
                        className="w-full flex items-center text-gray-300 hover:bg-purple-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        type="button"
                      >
                        Movies
                      </button>
                    </Link>
                    <Link to={"/"}>
                      <button
                        className="w-full flex items-center text-gray-300 hover:bg-purple-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        type="button"
                      >
                        TV Shows
                      </button>
                    </Link>
                    <Link to={"/"}>
                      <button
                        className="w-full flex items-center text-gray-300 hover:bg-purple-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        type="button"
                      >
                        Books
                      </button>
                    </Link>
                    <Link to={"/searchmovie"}>
                      <button
                        className="w-full flex items-center text-gray-300 hover:bg-purple-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        type="button"
                      >
                        Track Movie
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {/* <!-- Mobile menu button (hamburger) --> */}
              {auth?.userId ? (
                <div>
                  <div className="md:hidden flex items-center">
                    <button
                      className="outline-none mobile-menu-button"
                      onClick={toggleMobileMenu}
                    >
                      <svg
                        className="w-6 h-6 text-gray-300 text-sm font-medium"
                        x-show="!showMenu"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M4 6h16M4 12h16M4 18h16"></path>
                      </svg>
                    </button>
                  </div>

                  {/* <!-- Mobile menu --> */}
                  <div className="dropdown2">
                    <div
                      id="mobileMenu"
                      className="dropdown-content hidden absolute bg-white  text-base z-50 float-left py-2 list-none text-left rounded shadow-lg"
                    >
                      <Link to={"/"}>
                        <button
                          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                          onClick={toggleMobileMenu}
                        >
                          Movies
                        </button>
                      </Link>
                      <Link to={"/"}>
                        <button
                          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                          onClick={toggleMobileMenu}
                        >
                          TV Shows
                        </button>
                      </Link>
                      <Link to={"/"}>
                        <button
                          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                          onClick={toggleMobileMenu}
                        >
                          Books
                        </button>
                      </Link>
                      <Link to={"/searchmovie"}>
                        <button
                          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                          onClick={toggleMobileMenu}
                        >
                          Track Movie
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              <div className="w-40 flex-shrink-0 bg-purple-600 px-4 py-3">
                {auth?.userId ? (
                  <div className="dropdown2">
                    <button
                      className="userMenuButton w-full flex items-center justify-end text-gray-300 hover:bg-purple-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                      type="button"
                      onClick={toggleUserMenu}
                    >
                      {auth?.name}
                    </button>
                    <div
                      id="userDropdown"
                      className="dropdown-content hidden absolute bg-white  text-base z-50 float-left py-2 list-none text-left rounded shadow-lg"
                    >
                      <Link to={"/profile"}>
                        <button
                          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                          onClick={toggleUserMenu}
                        >
                          Profile
                        </button>
                      </Link>
                      {auth?.isAdmin && (
                        <div>
                          <Link to={"/addmovie"}>
                            <button className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
                              Add Movie
                            </button>
                          </Link>
                          <Link to={"/allmovies"}>
                            <button className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
                              All Movies
                            </button>
                          </Link>
                        </div>
                      )}
                      <button
                        onClick={(e) => {
                          logoutHandler(e);
                        }}
                        className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                ) : (
                  <Link to={`/login`}>
                    <button
                      className="w-full flex items-center justify-end text-gray-300 hover:bg-purple-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                      type="button"
                    >
                      Sign In
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
