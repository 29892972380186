import React from "react";
import PropTypes from "prop-types";

const Rating = ({ rating, color }) => {
  return (
    <div>
      <span>
        <i
          className={
            rating >= 1
              ? `fas fa-star ${color}`
              : rating >= 0.5
              ? `fas fa-star-half-alt ${color}`
              : "far fa-star"
          }
        ></i>
      </span>
      <span>
        <i
          className={
            rating >= 2
              ? `fas fa-star ${color}`
              : rating >= 1.5
              ? `fas fa-star-half-alt ${color}`
              : "far fa-star"
          }
        ></i>
      </span>
      <span>
        <i
          className={
            rating >= 3
              ? `fas fa-star ${color}`
              : rating >= 2.5
              ? `fas fa-star-half-alt ${color}`
              : "far fa-star"
          }
        ></i>
      </span>
      <span>
        <i
          className={
            rating >= 4
              ? `fas fa-star ${color}`
              : rating >= 3.5
              ? `fas fa-star-half-alt ${color}`
              : "far fa-star"
          }
        ></i>
      </span>
      <span>
        <i
          className={
            rating >= 5
              ? `fas fa-star ${color}`
              : rating >= 4.5
              ? `fas fa-star-half-alt ${color}`
              : "far fa-star"
          }
        ></i>
      </span>
    </div>
  );
};

Rating.defaultProps = {
  color: "text-red-400",
};

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
  color: PropTypes.string,
};

export default Rating;
